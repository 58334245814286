import React from 'react';
import { useParams } from 'react-router-dom';
import mockData from '@app/help-page/blocks/menu-bar/mock-new.json';

import styles from './styles/confluence-style.module.css';

const HelpPage: React.FC = () => {
  const { content_id } = useParams<{ content_id: string }>();
  console.log('content_id:', content_id);

  const article = mockData.find((item) => String(item.id) === content_id);

  function replaceIframeWithLocalVideo(htmlContent) {
    const videoMap = {
      'АП Конкуренты': '/video/AP_konkurenty.mp4',
      'АП Тематики': '/video/AP_tematiky.mp4',
      'АП Недовольство клиента': '/video/AP_nedovolstvo_klienta.mp4',
      'АП Оценка работы оператора': '/video/AP_Ozhenka_reboty_operatora.mp4',
      'АП Настроение клиента': '/video/AP_nastroenie_klienta.mp4',
      'Задание 1': '/video/Zadanie_1.mp4',
      'Задание 2': '/video/Zadanie_2.mp4',
      'Задание 3': '/video/Zadanie_3.mp4',
      'Дополнительные задания': '/video/Dopolnitelnue_zadania.mp4',
      'Все записи. Метаданные': '/video/Vse zapisi Metadannye.mp4',
      'Краткий обзор РА': '/video/Kratkiy obzor Rechevoy Analitiki.mp4',
      'Просмотр записей': '/video/Prosmotr zapisi.mp4',
      'Создание АП': '/video/Sozdanie Analiticheskogo proekta.mp4',
      'Составная метрика': '/video/Sostavnaya metrika.mp4',
      'Ручная метрика': '/video/Ruchnaya metrika.mp4',
      Отчёты: '/video/Otchety.mp4',
      Диаграммы: '/video/Diagrammy.mp4',
      'Изменение настроек АП': '/video/Izmenenie nastroek Analiticheskogo proekta.mp4',
      Дашборд: '/video/Dashbord.mp4',
      'Универсальный фильтр': '/video/Universalnyy filtr.mp4',
      Словари: '/video/Slovari.mp4',
      Шаблоны: '/video/Shablony.mp4',
      'Разделы меню Записи проекта и Отчёты': '/video/Razdely menyu Zapisi proekta i Otchety.mp4',
      'Облако фраз': '/video/Oblako fraz.mp4',
      Алиасы: '/video/Aliasy.mp4',
      'Правила распознавания': '/video/Pravila raspoznavaniya.mp4',
      'Ручная загрузка записей': '/video/Ruchnaya zagruzka zapisey.mp4',
      'Получатели рассылок': '/video/Poluchately_rassilok.mp4',
    };

    return htmlContent.replace(
      /<p>([^<]*)<iframe.*?src="https:\/\/drive\.google\.com\/file\/d\/.*?<\/iframe>\s*<\/p>/gi,
      (match, title) => {
        const sanitizedTitle = title.replace(/[“”"']/g, '').trim();
        const videoPath = videoMap[sanitizedTitle];
        if (videoPath) {
          return `
        <p>${sanitizedTitle}
          <video width="700px" height="400px " controls>
            <source src="${videoPath}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </p>`;
        }
        return match;
      },
    );
  }
  function removeStyleAttribute(htmlContent: string) {
    const regex = /<div([^>]*)data-macro-name="code"([^>]*)style="[^"]*"([^>]*)>/gi;
    return htmlContent.replace(regex, '<div$1data-macro-name="code"$2$3>');
  }

  function addBackgroundColor(htmlContent: string) {
    const backgroundColorRegex = /<div class="confluence-information-macro-body">/gi;
    return htmlContent.replace(
      backgroundColorRegex,
      '<div class="confluence-information-macro-body" style="background-color: rgb(233, 242, 255); padding: 8px; border-radius: 5px; margin: 20px;">',
    );
  }

  function removeStylePanelAttribute(htmlContent: string) {
    const regex = /<div class="panel conf-macro output-block"[^>]*style="[^"]*"[^>]*>/gi;
    return htmlContent.replace(regex, (match) => {
      return match.replace(/style="[^"]*"/, '');
    });
  }

  function removeStylePanelContentAttribute(htmlContent: string) {
    const regex = /<div class="panelContent"[^>]*style="[^"]*"[^>]*>/gi;
    return htmlContent.replace(regex, (match) => {
      return match.replace(/style="[^"]*"/, '');
    });
  }

  function addBackgroundPanelColor(htmlContent: string) {
    const backgroundColorRegex = /<div class="panel conf-macro output-block"(.*?)>/gi;
    return htmlContent.replace(
      backgroundColorRegex,
      '<div class="panel conf-macro output-block"$1 style="background-color: rgb(233, 242, 255); padding: 8px; border-radius: 5px; margin: 20px;">',
    );
  }

  function replaceGoogleDriveLinks(htmlContent: string) {
    const driveRegex =
      /<a[^>]+href="https:\/\/drive.google.com\/file\/d\/([a-zA-Z0-9_-]+)\/[^"]+"[^>]*>(.*?)<\/a>/gi;
    return htmlContent.replace(driveRegex, (match, id) => {
      const iframeSrc = `https://drive.google.com/file/d/${id}/preview`;
      return `<iframe src="${iframeSrc}" width="700px" height="400px" allow="autoplay"></iframe>`;
    });
  }

  function prependFaqToLinks(htmlContent: string) {
    return htmlContent.replace(/href="\/content\//g, 'href="/help');
  }

  function hrefEditor(htmlContent: string) {
    return htmlContent.replace(/href="\/wiki\/spaces\/gUuO9vPz7unm\/pages\//g, 'href="/help/');
  }

  function replaceImagePaths(htmlContent: string) {
    return htmlContent.replace(
      /<img([^>]*)src="[^"]*(?:\\|\/)content(?:\\|\/)([0-9]+)(?:\\|\/)([^"]*)"([^>]*)>/gi,
      (match, beforeSrc, contentId, fileName) => {
        const newSrc = `/content/${contentId}/${fileName}`;
        const classAttr = beforeSrc.match(/class="[^"]*"/) || [''];
        const altAttr = beforeSrc.match(/alt="[^"]*"/) || [''];
        const widthAttr = beforeSrc.match(/width="[^"]*"/) || [''];
        return `<img ${classAttr[0]} ${altAttr[0]} ${widthAttr[0]} src="${newSrc}" />`;
      },
    );
  }

  if (!article) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <p className="text-[18px] font-semibold">Выберите статью из меню</p>
      </div>
    );
  }

  let modifiedContent = article.description;
  modifiedContent = replaceIframeWithLocalVideo(modifiedContent);
  modifiedContent = removeStyleAttribute(modifiedContent as string);
  modifiedContent = addBackgroundColor(modifiedContent);
  modifiedContent = replaceGoogleDriveLinks(modifiedContent);
  modifiedContent = prependFaqToLinks(modifiedContent);
  modifiedContent = addBackgroundPanelColor(modifiedContent);
  modifiedContent = removeStylePanelAttribute(modifiedContent);
  modifiedContent = removeStylePanelContentAttribute(modifiedContent);
  modifiedContent = hrefEditor(modifiedContent);
  modifiedContent = replaceIframeWithLocalVideo(modifiedContent);
  modifiedContent = replaceImagePaths(modifiedContent);

  return (
    <div className="w-full h-full">
      {modifiedContent && modifiedContent.trim() ? (
        <div className="mx-[300px] my-[50px]">
          <h1 className="font-bold text-[24px] mb-[30px]">{article.title}</h1>
          <div
            className={`${styles['custom-content']}`}
            dangerouslySetInnerHTML={{ __html: modifiedContent }}
          />
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <p className="text-[18px] font-semibold">Статья отсутствует</p>
        </div>
      )}
    </div>
  );
};

export default HelpPage;
