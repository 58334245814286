import React, { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useAuthContext } from '@app/utils/auth-provider';
import { useGetUserInfoQuery } from '@app/store/api/get-user-info.api';
import { Loading } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useGetUserSettingsQuery } from '@app/store/api/user-settings.api';

import HeaderBlock from '@app/layouts/layout/blocks/header.block';
import FooterBlock from '@app/layouts/layout/blocks/footer.block';
import LayoutContainerBlock from '@app/layouts/layout/blocks/layout-container.block';
import MenuBar from '@app/help-page/blocks/menu-bar/menu-bar';
import mockData from '@app/help-page/blocks/menu-bar/mock-new.json';

import { ArticleItem } from '@app/help-page/blocks/types';

const faviconRtk = require('../../imgs/favicon/faviconRtk.png');
const faviconDefault = require('../../imgs/favicon/faviconDefault.png');

const HelpLayout: FC = () => {
  const { isAuth } = useAuthContext();
  const [layoutLoading, setLayoutLoading] = useState(true);
  const { isLoading: loading } = useGetUserSettingsQuery(undefined, { skip: !isAuth });
  const { isFetching } = useGetUserInfoQuery(undefined, { skip: !isAuth });
  const { t } = useTranslation('common.pageTitle');

  useEffect(() => {
    if (isFetching || loading) {
      setLayoutLoading(true);
    } else {
      setTimeout(() => setLayoutLoading(false), 700);
    }
  }, [isFetching, loading]);

  if (!isAuth)
    return (
      <div className="bg-basic_app_bg">
        <Outlet />
      </div>
    );

  if (layoutLoading) return <Loading />;

  const parseMenuItem = (item: any): ArticleItem => {
    const parsedItem: ArticleItem = {
      ...item,
      child: item.child ? JSON.parse(item.child) : { children: [] },
    };

    if (parsedItem.child && parsedItem.child.children.length > 0) {
      parsedItem.child.children = parsedItem.child.children.map((childItem: any) =>
        parseMenuItem(childItem),
      );
    }

    return parsedItem;
  };

  const processedMock: ArticleItem[] = mockData.map((item: any) => parseMenuItem(item));
  console.log('processedMock', processedMock);

  return (
    <div className="bg-basic_app_bg border-l-[3px] border-l-speech_analitics">
      <HeaderBlock />
      <div className="flex">
        <MenuBar menuList={processedMock} />
        <div className="flex flex-col flex-1 relative overflow-hidden">
          <LayoutContainerBlock>
            <Outlet />
          </LayoutContainerBlock>
          <FooterBlock />
          <Helmet>
            <link
              rel="icon"
              type="image/png"
              href={process.env.REACT_APP_THEME === 'rtk' ? faviconRtk : faviconDefault}
              sizes="16x16"
            />
            <title>{t('title')}</title>
          </Helmet>
        </div>
      </div>
    </div>
  );
};

export default HelpLayout;
